html, body {
  height: 100%;
  color: #424242;
}

body {
  background: #161616 url("../img/bg.jpg") center center;
}

.body-wrapper {
  height: 100%  ;
}

.wrapper {
  padding: 1rem;
  border-radius: .5rem;
  padding-bottom: 0;
  background: rgba(245, 245, 245, 0.8);
}

@import "index";
