.index {
  .profile-img {
    margin-top: 1rem;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: .6rem;
  }

  h2 {
    font-size: 1rem;
    margin: 0;
  }

  a {
    color: white;
  }
}

.main-menu {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
  margin-bottom: 0 !important;

  &-item {
    text-align: center;
    width: 20%;

    .content {
      display: block;
      padding: 1rem;
      padding-bottom: 1.4rem;
      padding-top: 1.4rem;
      font-size: 2.6em;
    }

    &.first {
      border-bottom-left-radius: .5rem;
    }

    &.last {
      border-bottom-right-radius: .5rem;
    }
  }
}

// colors

.google-style {
  background: #db4437;
}

.twitter-style {
  background: #55acee;
  color: white !important;
}

.github-style {
  background: #f5f5f5;
  color: #333 !important;
}

.slideshare-style {
  background: #2d2d2d;
  color: #d7d7d7;
}

.medium-style {
  background: #00ab6b;
  color: white !important;
}
